// Edit user module menu - About Me / Social Media

let editUserModalMenu = document.querySelectorAll(".js-edit-user-modal-menu");

document.addEventListener('click', function (event) {

    if (event.target.matches('.js-edit-user-open-modal')) {
        adjustModalContent(event.target);
        event.preventDefault();
    }

    if (event.target.matches('.js-edit-user-modal-menu')) {
        adjustModalContent(event.target);
        event.preventDefault();
    }

    if (event.target.matches('.js-employee-questions-show')) {
        toggleAllQuestions(event.target, 'show');
        event.preventDefault();
    }

    if (event.target.matches('.js-employee-questions-hide')) {
        toggleAllQuestions(event.target, 'hide');
        event.preventDefault();
    }

}, false);

function adjustModalContent(item)
{
    let activeItem = item.getAttribute("data-modal-menu");

    // Remove is-active
    editUserModalMenu.forEach(function (item) {
        item.classList.remove("is-active");
    });

    // Add to clicked
    document.querySelector(".js-edit-user-modal-menu[data-modal-menu='" + activeItem + "']").classList.add("is-active");

    // Switch content
    document.querySelector(".js-user-edit-modal-about").classList.remove("is-active");
    document.querySelector(".js-user-edit-modal-social").classList.remove("is-active");
    document.querySelector(".js-user-edit-modal-" + activeItem).classList.add("is-active");
}

function toggleAllQuestions(item, action)
{
    // Show all question.
    document.querySelectorAll(".employee-questions__item").forEach(function (item) {
        if ('show' === action) {
            item.classList.add("is-active");
        } else {
            item.classList.remove("is-active");
        }
    });

    // Show all buttons.
    document.querySelectorAll(".employee-questions__see-all").forEach(function (item) {
        item.classList.add("is-active");
    });

    // Hide button.
    item.classList.remove("is-active");
}
