// Toggle mobile navigation.

let toggleNavMobile = document.querySelector(".js-toggle-nav-mobile");
let navMobile = document.querySelector(".js-nav-mobile");
let body = document.querySelector("body");

if (toggleNavMobile) {
    toggleNavMobile.addEventListener(
        "click",
        function(e) {
            toggleNavMobile.classList.toggle("is-opened");
            body.classList.toggle("is-opened");
            navMobile.classList.toggle("is-opened");
            e.preventDefault();
        },
        false
    );
}
