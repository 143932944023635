// Filter
let listingFilters = document.querySelectorAll(".js-listing-filter");

// Submit form on change dropdown value
if (listingFilters) {
    listingFilters.forEach(elem => {
        elem.addEventListener("change", e => {
            let form = elem.closest("form");
            form.submit();
        });
    });
}

