// Toggle filter dropdowns on mobile.

let toggleProjListFilter = document.querySelector( ".js-toggle-listing-filter" );
let projListFilter = document.querySelectorAll( ".js-listing-filter" );

if ( toggleProjListFilter ) {
    toggleProjListFilter.addEventListener( "click", function ( e ) {
        projListFilter.forEach( function ( item ) {
            item.classList.toggle( "is-visible" );
        } );
        e.preventDefault();
    } );
}
