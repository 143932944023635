/**
 * Global variables
 */
let liveSearchTimer = null;
let liveSearchResults = "";

import { doPopupBoxAjaxCall } from "./hp-feed.js";

/**
 * Call ajax for search.
 *
 * @param el
 */
function doliveSearchAjaxCall(el) {
    // Prepare XHR Request.
    let xhr = new XMLHttpRequest();

    // Get post ID.
    let val = el.value;

    // Ipen XHR Request & set correct header
    xhr.open("POST", settings.ajaxurl, true);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    // Callback on response.
    xhr.onreadystatechange = function() {
        // Hide the loader.
        toggleLoader(false);

        // Hide more btn
        toggleMoreBtn(false);

        // readyState 4 means the request is done.
        var DONE = 4;

        // status 200 is a successful return.
        var OK = 200;

        // Check response.
        if (xhr.readyState === DONE) {
            if (xhr.status === OK) {
                // Fill modal with content.
                let json = JSON.parse(xhr.responseText);
                let data = json.data;

                createResultsHTML(data);

                toggleResults();

                //replaceModalContent(xhr.responseText);
            } else {
                // Error.
            }
        }
    };

    // Hide Options.
    toggleOptions(false);

    // Hide results.
    toggleResults(false);

    // Display the loader.
    toggleLoader(true);

    // prepare url.
    let url = "action=live_search&s=" + val;
    let cpts = document.querySelector('input[name="cpts"]:checked').value;
    if (cpts !== "all") {
        url += "&cpts=" + cpts;
    }

    // Finally do the call with the right parametetrs.
    xhr.send(url);
}

/**
 * Prepare the final Results HTML
 *
 * @param data
 */
function createResultsHTML(data) {
    let element = document.getElementsByClassName("nav-search-results");
    if (element.length > 0) {
        // Empty results string.
        liveSearchResults = "";

        // Loop data and prepare everything in "liveSearchResults".
        data.forEach(createResultsSection);

        if (liveSearchResults === "") {
            liveSearchResults =
                '<div class="nav-search-results__item"><em>Sorry, no result found.</em></div>';
        } else {
            // Show more field if some results were
            toggleMoreBtn(true);
        }

        // set results container content.
        element[0].innerHTML = liveSearchResults;
    }
}

/**
 * Callback function to create Section.
 *
 * @param item
 * @param index
 */
function createResultsSection(item, index) {
    if (item.choices.length > 0) {
        liveSearchResults +=
            '<h6 class="nav-search-results__category">' + item.label + "</h6>";
        item.choices.forEach(createResultsSectionItem);
    }
}

/**
 * Callback function to create Section item.
 *
 * @param item
 * @param index
 */
function createResultsSectionItem(item, index) {
    let attributes = "";

    // Add ID for ajax conent.
    if (item.type && item.type === "post" && item.id) {
        attributes = 'data-id="' + item.id + '"';
    }

    if (item.type && item.type === "survey" && item.id) {
        attributes = 'data-id="' + item.id + '"';
    }

    // Add nonce and modal opening data attributes.
    if (item.type && item.type === "post" && item.nonce) {
        attributes = attributes + ' data-nonce="' + item.nonce + '"';
        attributes = attributes + ' data-toggle="modal"';
        attributes = attributes + ' data-target="#news-popup"';
    }

    // Add nonce and modal opening data attributes.
    if (item.type && item.type === "survey" && item.nonce) {
        attributes = attributes + ' data-nonce="' + item.nonce + '"';
        attributes = attributes + ' data-toggle="modal"';
        attributes = attributes + ' data-target="#survey-popup"';
    }

    liveSearchResults +=
        '<a class="nav-search-results__item" href="' +
        item.value +
        '" ' +
        attributes +
        ">" +
        item.label +
        "</a>";
}

/**
 * Replace the modal content
 *
 * @param content
 */
function replaceModalContent(content) {
    let modal = document.querySelector(".modal-content");

    // Exit if no modal found.
    if (!modal) {
        return;
    }

    // Fill modal with content.
    modal.innerHTML = content;
}

/**
 * Toggle the Results section. Hide or show.
 */
function toggleResults(bool = true) {
    let element = document.getElementsByClassName("nav-search-results");
    if (element.length > 0) {
        if (bool) {
            element[0].style.display = "block";
        } else {
            element[0].style.display = "none";
        }
    }
}

/**
 * Toggle the Search Options section. Hide or show.
 */
function toggleOptions(bool = true) {
    let element = document.getElementsByClassName("nav-search-options");
    if (element.length > 0) {
        if (bool) {
            element[0].style.display = "block";
        } else {
            element[0].style.display = "none";
        }
    }
}

/**
 * Show or hide the loader indicator.
 */
function toggleLoader(bool = true) {
    let element = document.getElementsByClassName("nav-search-loader");
    if (element.length > 0) {
        if (bool) {
            element[0].style.display = "block";
        } else {
            element[0].style.display = "none";
        }
    }
}

/**
 * Show or hide the loader indicator.
 */
function toggleMoreBtn(bool = true) {
    let element = document.getElementsByClassName("nav-search-more-btn");
    if (element.length > 0) {
        if (bool) {
            element[0].style.display = "block";
        } else {
            element[0].style.display = "none";
        }
    }
}

/**
 * Call ajax search when editing search input.
 */
let searchField = document.getElementsByClassName("nav-search-form__field");
if (searchField.length > 0) {
    searchField[0].addEventListener(
        "keyup",
        function(ev) {
            if (this.value.length < 1) {
                // Hide the results section.
                toggleResults(false);

                // Show the Options section.
                toggleOptions(true);
            }

            // Ignore non-important keys.
            if (
                (ev.keyCode >= 9 && ev.keyCode <= 45) ||
                (ev.keyCode >= 91 && ev.keyCode <= 93) ||
                (ev.keyCode >= 112 && ev.keyCode <= 145)
            ) {
                return;
            }

            // Clear the timeout for ajax call.
            window.clearTimeout(liveSearchTimer);

            // Only do Ajax if 3 letters or more.
            if (this.value.length >= 2) {
                liveSearchTimer = window.setTimeout(function() {
                    // Call ajax search/
                    doliveSearchAjaxCall(ev.target);
                }, 500);
            }
        },
        false
    );
}

document.addEventListener(
    "click",
    function(event) {
        const is_post = event.target.matches(
            ".nav-search-results__item[data-target='#news-popup']"
        )
            ? true
            : false;

        const is_survey = event.target.matches(
            ".nav-search-results__item[data-target='#survey-popup']"
        )
            ? true
            : false;

        // If the clicked element doesn't have the right selector, bail
        if (!is_post && !is_survey) {
            return;
        }

        // Don't follow the link
        event.preventDefault();

        let postId = event.target.getAttribute("data-id");

        if (!postId) {
            return;
        }

        if (is_post) {
            // Empty modal content.
            replaceModalContent("", "news");

            // Call ajax content.
            doPopupBoxAjaxCall(event.target, "news");
        }

        if (is_survey) {
            // Empty modal content.
            replaceModalContent("", "survey");

            // Call ajax content.
            doPopupBoxAjaxCall(event.target, "survey");
        }
    },
    false
);
