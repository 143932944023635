import "jquery"; // Wordpress uploader is using jquery

let uploadButtons = document.querySelectorAll(".js-media-upload-button");

if (uploadButtons) {
    uploadButtons.forEach(function(button) {
        button.addEventListener("click", e => {
            e.preventDefault();

            let fileFrame; // variable for the wp.media fileFrame

            // if the fileFrame has already been created, just reuse it
            if (fileFrame) {
                fileFrame.open();
                return;
            }

            fileFrame = wp.media.frames.fileFrame = wp.media({
                library: {
                    type: "image" // limits the frame to show only images
                },
                multiple: false
            });

            // Hide some uploader views
            fileFrame.el.className =
                fileFrame.el.className + " wp-frontend-uploader";

            // On image select add attachemnt url and id to fields
            fileFrame.on("select", () => {
                let attachment = fileFrame
                    .state()
                    .get("selection")
                    .first()
                    .toJSON();
                let attachmentId = attachment.id;
                let parent = button.parentNode;

                // Add attachment id to field
                let photoInput = parent.querySelectorAll(".js-media-upload-id");
                if (photoInput.length) {
                    photoInput[0].value = attachmentId;
                }

                // Get and set attachment preview
                let photoPreview = parent.querySelectorAll(
                    ".js-media-upload-preview"
                );
                let nonce = button.getAttribute("data-nonce");
                setAttachment(attachmentId, photoPreview, nonce);
            });

            fileFrame.open();
        });
    });
}

/**
 * Call ajax to get attachment size
 */
function setAttachment(attachmentId, photoPreview, nonce) {
    if (attachmentId && nonce) {
        // Prepare XHR Request.
        let xhr = new XMLHttpRequest();

        xhr.open("POST", settings.ajaxurl, true);

        xhr.setRequestHeader(
            "Content-type",
            "application/x-www-form-urlencoded"
        );

        // Callback on response.
        xhr.onreadystatechange = function() {
            // readyState 4 means the request is done.
            const LOADING = 3;
            const DONE = 4;

            // status 200 is a successful return.
            const OK = 200;

            // Query all profile picture wrap elements
            const loadingClass = "js-loading";
            let loadingEls = document.querySelectorAll(
                ".js-media-upload-preview-load"
            );

            // Display image loader on loading
            if (xhr.readyState === LOADING) {
                loadingEls.forEach(el => {
                    el.classList.add(loadingClass);
                });
            }

            // Check response.
            if (xhr.readyState === DONE) {
                if (xhr.status === OK) {
                    // Return atachment url
                    if (photoPreview.length) {
                        photoPreview[0].setAttribute("src", xhr.responseText);

                        // Query all profile image elements and change src attribute.
                        let smallPhotoEls = document.querySelectorAll(
                            ".nav-user__avatar"
                        );

                        smallPhotoEls.forEach(el => {
                            el.setAttribute("src", xhr.responseText);
                        });

                        // Remove loader class
                        loadingEls.forEach(el => {
                            el.classList.remove(loadingClass);
                        });
                    }
                } else {
                    // Error.
                }
            }
        };

        // Finally do the call with the right parametetrs.
        xhr.send(
            "action=set-employee-image&attachment-id=" +
                attachmentId +
                "&wp_nonce=" +
                nonce
        );
    }
}
