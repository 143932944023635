let promptClass = "outdated-browser";

/**
 * Close outdated broswer prompt
 */
document.addEventListener("click", function(event) {
    let button = event.target.closest(".js-close-outdated-browser");

    if (button) {
        event.preventDefault();
        closePrompt();
    }
});

/**
 * Close outdated browser prompt
 */
function closePrompt() {
    // Remove outdated browser prompt
    let prompt = document.getElementsByClassName(promptClass)[0];
    prompt.remove();
}
