// Events post type
let icsDownloadLinks = document.querySelectorAll('.js-download-ics');

// Classic button filter
if (icsDownloadLinks) {

    icsDownloadLinks.forEach(function (item) {

        item.addEventListener("click", (e) => {

            e.preventDefault();

            let fileUrl = item.getAttribute("href");

            if( fileUrl) {
                let request = new XMLHttpRequest();
                request.open('GET', fileUrl);
                request.onload = function () {
                    if (this.status >= 200 && this.status < 400) {
                        // Force file download
                        window.location = fileUrl;
                    } else {
                        // If fail
                        console.log(this.response);
                    }
                };

                request.send();
            }

        }, false);
    });
}
