// Toggle search navigation.
let searchWrapper = document.querySelector('.js-search-wrapper');

document.addEventListener('click', function (event) {
    if (!event.target.closest('.js-search-dropdown')) {
        if (searchWrapper.classList.contains('is-search-opened')) {
            searchWrapper.classList.remove("is-search-opened");
        }
    }

    if (event.target.matches('.js-open-search')) {
        searchWrapper.classList.add("is-search-opened");
    }

    if (event.target.matches('.js-close-search')) {
        searchWrapper.classList.remove("is-search-opened");
    }
});
