import {doNewBoxAjaxCall} from "./hp-feed";

let autoplayVideo = function (parent) {

    // Look for a YouTube, Vimeo, or HTML5 video in the parent container.
    var video = parent.querySelector('iframe[src*="www.youtube.com"], iframe[src*="player.vimeo.com"], video');

    // Bail if the modal doesn't have a video.
    if (!video) {
        return;
    }

    // If an HTML5 video, play it.
    if (video.tagName.toLowerCase() === 'video') {
        video.play();
        return;
    }

    // Add autoplay to video src
    // video.src: the current video `src` attribute
    // (video.src.indexOf('?') < 0 ? '?' : '&'): if the video.src already has query string parameters, add an "&". Otherwise, add a "?".
    // 'autoplay=1': add the autoplay parameter
    video.src = video.src + (video.src.indexOf('?') < 0 ? '?' : '&') + 'autoplay=1';

};

document.addEventListener('click', function (event) {

    // If the clicked element doesn't have the right selector, bail
    if (!event.target.matches('.wp-block-embed.is-type-video, .wp-block-video')) {
        return;
    }

    // Add class to remove overlay and play video.
    if ( ! event.target.classList.contains('js-remove-overlay') ) {
        event.target.classList.add("js-remove-overlay");

        // Play the video after click.
        autoplayVideo(event.target);
    }

}, false);
