// Choices.js.

import Choices from "choices.js";

let choises = ".js-choice";

if ( document.querySelector( choises ) ) {
    const choices = new Choices( choises, {
        itemSelectText: "",
    } );
}
