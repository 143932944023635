// Masonry

import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import $ from "jquery";

const masonryLayout = document.querySelectorAll('.js-masonry-layout');
export const initializedMsnry = []; // We need to export this variable

const msnryOptions = {
    itemSelector: '.js-masonry-item',
    columnWidth: '.js-masonry-item',
    percentPosition: true,
    visibleStyle: { transform: 'translateY(0)', opacity: 1 },
    hiddenStyle: { transform: 'translateY(100px)', opacity: 0 }
};

if (masonryLayout.length > 0) {
    masonryLayout.forEach(function (grid) {
        // Get type so we can initialize multiple Masonries and later reinitialize only needed one
        // 'default' is used on all other pages, when there is only one Masonry container
        let type = grid.dataset.filterType ? grid.dataset.filterType : 'default';

        initializedMsnry[type] = new Masonry(grid, msnryOptions);

        // Layout Masonry after each image loads
        imagesLoaded(grid).on('progress', function() {
            initializedMsnry[type].layout();
        });
    });

    // Recalculate Masonry on tabs change
    // We need to use jQuery because of Bootstrap
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        let type = e.target.dataset.filterType;

        if (initializedMsnry[type]) {
            initializedMsnry[type].layout();
        }
    });
}
