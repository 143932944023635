// Toggle submenu on mobile nav.

let toggleNavSub = document.querySelectorAll( ".js-toggle-nav-sub" );

if ( window.matchMedia( "(max-width: 1199px)" ).matches ) {
    if ( toggleNavSub ) {
        toggleNavSub.forEach( function ( item ) {
            item.addEventListener( "click", function ( e ) {
                e.preventDefault();
                let parent = item.parentElement;
                parent.classList.toggle( "is-opened" );
                parent.nextElementSibling.classList.toggle( "is-opened" );
            }, false );
        } );
    }
}
