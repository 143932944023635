/**
 * Call ajax for specific pin element
 *
 * @param el
 */
function doFavouritesAjaxCall(el, action) {
    // Get post ID.
    let id = el.getAttribute('data-id');

    if (id) {
        // Prepare XHR Request.
        let xhr = new XMLHttpRequest();

        xhr.open('POST', settings.ajaxurl, true);

        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        // Disable further clicking.
        el.style.pointerEvents = "none";

        // Callback on response.
        xhr.onreadystatechange = function () {
            // readyState 4 means the request is done.
            var DONE = 4;

            // status 200 is a successful return.
            var OK = 200;

            // Check response.
            if (xhr.readyState === DONE) {
                if (xhr.status === OK) {
                    // Append all items to current DOM.
                    let userFavourites = document.querySelector('.my-favorites--user');
                    let div = document.createElement('div');

                    let json = JSON.parse(xhr.responseText);

                    // restore pointer events.
                    el.style.pointerEvents = "auto";

                    // Exist if not success.
                    if (!json.success) {
                        alert(json.data);
                        return;
                    }

                    let data = json.data;

                    if ('add-to-favourites' === action) {
                        if (data.hasOwnProperty('url')) {
                            div.innerHTML = '<li class="my-favorites__item"><a class="my-favorites__link is-active" href="' + data.url + '">' + data.title + ' <span class="my-favorites__delete | d-none | js-delete-favorite" data-id="' + data.id + '" data-nonce="' + data.nonce + '">' + data.actionTitle + '</span></a></li>';
                            userFavourites.insertBefore(div.firstChild, userFavourites.firstChild);

                            setTimeout(function () {
                                userFavourites.firstChild.firstChild.classList.remove("is-active");
                            }, 150);
                        }
                    }

                    if ('remove-from-favourites' === action) {
                        let li = el.parentNode.parentNode;
                        li.parentNode.removeChild(li);
                    }

                } else {
                    // Error.
                }
            }
        };

        // Finally do the call with the right parametetrs.
        xhr.send("action="+action+"&id="+id+"&wp_nonce=" + el.getAttribute('data-nonce'));
    }
}

/**
 * Call ajax Add To Favourites action on click.
 *
 * @param content
 */
document.addEventListener('click', function (event) {

    // If the clicked element doesn't have the right selector, bail
    if (!event.target.matches('.js-nav-fav-add, .js-delete-favorite')) {
        return;
    }

    // Don't follow the link.
    event.preventDefault();

    // Call ajax pin method
    if (event.target.matches('.js-nav-fav-add')) {
        doFavouritesAjaxCall(event.target, 'add-to-favourites')
    }

    if (event.target.matches('.js-delete-favorite')) {
        doFavouritesAjaxCall(event.target, 'remove-from-favourites')
    }

}, false);
