import InfiniteScroll from "infinite-scroll";
import imagesLoaded from "imagesloaded";
import { initializedMsnry } from "./masonry-layout";

// Make imagesLoaded available for InfiniteScroll.
// This library is used in outlayer and provides compatibility with Masonry.
InfiniteScroll.imagesLoaded = imagesLoaded;

// Initialize default Infinite scroll used on archives
const infScrollCont = document.getElementsByClassName(
    "js-infinite-scroll-container"
);

if (infScrollCont && infScrollCont.length > 0) {
    var infScrollDefault = new InfiniteScroll(infScrollCont[0], {
        path: ".js-infinite-scroll-next-page",
        append: ".js-infinite-scroll-item",
        history: false,
        status: ".js-infinite-scroll-loader",
        button: ".js-infinite-scroll-next-page",
        loadOnScroll: false,
        outlayer: initializedMsnry["default"] // use Masonry layout
    });

    /**
     * After we append all items, get all separators and remove the ones we do not need.
     */
    infScrollDefault.on("append", function(response, path, items) {
        // Get all separators.
        let separators = document.querySelectorAll(
            ".js-infinite-scroll-container .masonry-layout__item--full"
        );
        if (separators.length > 0) {
            // Loop through every separator.
            separators.forEach(function(separator) {
                // Get previous item to check 'data-period' change.
                let prev_item = separator.previousElementSibling;

                // Remove separator if previous item has same 'data-period'.
                if (
                    prev_item.getAttribute("data-period") &&
                    prev_item.getAttribute("data-period") ===
                        separator.getAttribute("data-period")
                ) {
                    // Remove separator.
                    separator.parentNode.removeChild(separator);

                    // Recalculate masonry positions.
                    initializedMsnry["default"].layout();
                }
            });
        }
    });
}

// Initialize HP feed Infinite scroll
const infScrollFeed = [];
const infScrollFeedContainer = document.querySelectorAll(
    ".js-hp-infinite-scroll-container"
);

if (infScrollFeedContainer.length > 0) {
    // Initialize Infinite scroll in all tabs, each tab will have separate instance
    infScrollFeedContainer.forEach(function(container) {
        const filterType = container.dataset.filterType;
        const nextLink = document.getElementsByClassName(
            "js-infinite-scroll-next-page-" + filterType
        );

        // Only initialize when there are more pages
        if (nextLink.length > 0) {
            initInfScroll(filterType, container);
        }
    });
}

function initInfScroll(filterType, container) {
    infScrollFeed[filterType] = new InfiniteScroll(container, {
        path: function() {
            // Use button as path so we can pass query args to next page via url
            let nextPageBtn = document.querySelector(
                ".js-infinite-scroll-next-page-" + filterType
            );
            if (nextPageBtn) {
                return nextPageBtn.href;
            }
        },
        checkLastPage: ".js-infinite-scroll-next-page-" + filterType,
        append: " .js-infinite-scroll-item-" + filterType,
        history: false,
        status: ".js-infinite-scroll-loader-" + filterType,
        // button: '.js-infinite-scroll-next-page-' + filterType,
        loadOnScroll: false, // disable by default, we will load next pages manually
        outlayer: initializedMsnry[filterType], // use Masonry layout
        // debug: true,
        scrollThreshold: 300,
        onInit: function() {
            // Load masonry and update next page btn
            // We need to do this so we can use btn as InfScroll path and additional pass query args (last_post_id)
            this.on("append", function(response, path, items) {
                var nextPageUrl = getNextPageUrl(response, filterType);

                // Get current btn
                var nextPageBtn = document.querySelector(
                    ".js-infinite-scroll-next-page-" + filterType
                );

                // Check if not last page (url is missing) and update next page btn
                if (nextPageUrl) {
                    nextPageBtn.href = nextPageUrl;
                }
            });
        }
    });

    // This is important!
    // By default, InfScr is automatically loading next pages on scroll and is doing that at the same moment in all tabs.
    // We need to disable this behaviour and load next pages only in current opened tab by manually binding scroll event.
    infScrollFeed[filterType].on("scrollThreshold", function(
        response,
        path,
        items
    ) {
        const openedTabCont = document.querySelector(
            ".tab-pane.active .js-hp-infinite-scroll-container"
        );
        const filterType = openedTabCont.dataset.filterType;

        if (typeof infScrollFeed[filterType] !== "undefined") {
            infScrollFeed[filterType].loadNextPage();
        }
    });
}

// Initialize Search Infinite scroll
const infScrollSearchFeed = [];
const infScrollSearchContainer = document.querySelectorAll(
    ".js-search-infinite-scroll-container"
);

if (infScrollSearchContainer.length > 0) {
    // Initialize Infinite scroll in all tabs, each tab will have separate instance
    infScrollSearchContainer.forEach(function(container) {
        const filterType = container.dataset.filterType;
        const nextLink = document.getElementsByClassName(
            "js-infinite-scroll-next-page-" + filterType
        );

        // Only initialize when there are more pages
        if (nextLink.length > 0) {
            initSearchScroll(filterType, container);
        }
    });
}

function initSearchScroll(filterType, container) {
    infScrollSearchFeed[filterType] = new InfiniteScroll(container, {
        path: ".js-infinite-scroll-next-page-" + filterType,
        append: " .js-infinite-scroll-item-" + filterType,
        history: false,
        status: ".js-infinite-scroll-loader-" + filterType,
        button: ".js-infinite-scroll-next-page-" + filterType,
        loadOnScroll: false, // disable by default, we will load next pages manually
        outlayer: initializedMsnry[filterType], // use Masonry layout
        onInit: function() {
            // Load masonry and update next page btn
            this.on("append", function(response, path, items) {
                var nextPageUrl = getNextPageUrl(response, filterType);

                // Get current btn
                var nextPageBtn = document.querySelector(
                    ".js-infinite-scroll-next-page-" + filterType
                );

                // Check if not last page (url is missing) and update next page btn
                if (nextPageUrl) {
                    nextPageBtn.href = nextPageUrl;
                }
            });
        }
    });
}

/**
 * Get URL for next infinite scroll page.
 */
function getNextPageUrl(responseDoc, filterType) {
    let btn = responseDoc.querySelector(
        ".js-infinite-scroll-next-page-" + filterType
    );
    if (btn) {
        return responseDoc.querySelector(
            ".js-infinite-scroll-next-page-" + filterType
        ).href;
    }

    return false;
}
