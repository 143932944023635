import $ from "jquery";

/**
 * Call ajax for specific news box modal content.
 *
 * @param el
 */
export function doPopupBoxAjaxCall(id, type = "news") {
    // Prepare XHR Request.
    let xhr = new XMLHttpRequest();

    // Ipen XHR Request & set correct header
    xhr.open("POST", settings.ajaxurl, true);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    // Callback on response.
    xhr.onreadystatechange = function() {
        // readyState 4 means the request is done.
        var DONE = 4;

        // status 200 is a successful return.
        var OK = 200;

        // Check response.
        if (xhr.readyState === DONE) {
            if (xhr.status === OK) {
                // Fill modal with content.
                replaceModalContent(xhr.responseText, type);
            } else {
                // Error.
            }
        }
    };

    // Finally do the call with the right parametetrs.
    xhr.send("action=get-" + type + "-box-detail&id=" + id);
}

/**
 * Replace the modal content
 *
 * @param content
 */
function replaceModalContent(content, type = "news") {
    let modal;

    modal = document.querySelector("." + type + "-popup__content");

    // Exit if no modal found.
    if (!modal) {
        let container = document.querySelector(".container");

        // Exit if no container element found.
        if (!container) {
            return;
        }

        // Add the modal DOM element.
        container.insertAdjacentHTML(
            "beforeend",
            eval("settings." + type + "_popup_modal_html")
        );

        modal = document.querySelector("." + type + "-popup__content");
    }

    // Fill modal with content.
    modal.innerHTML = content;
}

/**
 * Call ajax modal content on news-box more link click.
 */
document.addEventListener(
    "click",
    function(event) {
        const is_post =
            event.target.matches(".more-link--news-box") ||
            event.target.matches(
                ".nav-search-results__item[data-target='#news-popup']"
            )
                ? true
                : false;

        const is_survey =
            event.target.matches(".more-link--survey-box") ||
            event.target.matches(
                ".nav-search-results__item[data-target='#survey-popup']"
            )
                ? true
                : false;

        // If the clicked element doesn't have the right selector, bail
        if (!is_post && !is_survey) {
            return;
        }

        // Don't follow the link
        event.preventDefault();

        let postId = event.target.getAttribute("data-id");

        if (is_post) {
            // Empty modal content.
            replaceModalContent("", "news");

            // Add post id to url
            window.history.pushState("", "", "/?post-id=" + postId);

            // Call ajax content.
            doPopupBoxAjaxCall(postId, "news");
        }

        if (is_survey) {
            // Empty modal content.
            replaceModalContent("", "survey");

            // Add survey id to url
            window.history.pushState("", "", "/?survey-id=" + postId);

            // Call ajax content.
            doPopupBoxAjaxCall(postId, "survey");
        }
    },
    false
);

/**
 * Load post in popup on document load
 */
window.addEventListener("load", function(event) {
    const urlParams = new URLSearchParams(window.location.search);
    const postId = urlParams.get("post-id");
    const surveyId = urlParams.get("survey-id");

    if (postId) {
        doPopupBoxAjaxCall(postId, "news");

        $("#news-popup").modal("show");
    }

    if (surveyId) {
        doPopupBoxAjaxCall(surveyId, "survey");

        $("#survey-popup").modal("show");
    }
});
