/**
 * Call ajax for specific pin element
 *
 * @param el
 */
function doPinAjaxCall(el) {
    // Get post ID.
    let id = el.getAttribute('data-id');

    if (id) {

        let pinnedClass = 'pin--pinned';
        let pinAnimationClass = 'pin--added';

        // Prepare XHR Request.
        let xhr = new XMLHttpRequest();

        xhr.open('POST', settings.ajaxurl, true);

        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        // Callback on response.
        xhr.onreadystatechange = function () {
            // readyState 4 means the request is done.
            var DONE = 4;

            // status 200 is a successful return.
            var OK = 200;

            // Check response.
            if (xhr.readyState === DONE) {
                if (xhr.status === OK) {
                    // Fill modal with content.
                    if (!el.classList.contains(pinnedClass)) {
                        // Run animation and make pin pinned
                        el.classList.add(pinAnimationClass, pinnedClass);
                    } else {
                        // Unpin
                        el.classList.remove(pinnedClass, pinAnimationClass);
                    }

                } else {
                    // Error.
                }
            }
        };

        // Finally do the call with the right parametetrs.
        xhr.send("action=pin-the-post&id=" + id + "&wp_nonce=" + el.getAttribute('data-nonce'));
    }
}

/**
 * Call ajax pin action on pin icon click.
 *
 * @param content
 */
document.addEventListener('click', function (event) {

    // If the clicked element doesn't have the right selector, bail
    if (!event.target.matches('.js-pin-the-post')) {
        return;
    }

    // Don't follow the link
    event.preventDefault();

    // Call ajax pin method
    doPinAjaxCall(event.target)

}, false);
