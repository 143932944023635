/**
 * Call ajax for survey submit
 *
 * @param el
 */
function doSurveySubmit(el) {
    // Get post ID.
    let id = el.getAttribute("data-id");
    if (id) {
        let questions = el.querySelectorAll(".js-survey-question");
        let answers = [];

        questions.forEach(question => {
            let questionId = question.getAttribute("data-question-id");

            // Skip not valid questions
            if (!questionId) {
                return;
            }

            // Remove from question previous error class
            question.classList.remove("error");

            // Find if is selected one answer
            let selectedAnswerEl = question.querySelector(
                ".js-survey-answer-input:checked"
            );

            selectedAnswer = selectedAnswerEl
                ? selectedAnswerEl.getAttribute("value")
                : "";

            // Push answer to answers list
            let answer = {};
            answer[questionId] = selectedAnswer;
            answers.push(answer);
        });

        // Prepare XHR Request.
        let xhr = new XMLHttpRequest();

        xhr.open("POST", settings.ajaxurl, true);

        xhr.setRequestHeader(
            "Content-type",
            "application/x-www-form-urlencoded"
        );

        let response = el.querySelector(".js-survey-response");

        if (response) {
            // Remove previous classes from response object
            response.classList.remove("error", "success");

            // Clear response previous content
            response.innerHTML = "";
        }

        // Add loading class to form
        el.classList.add("loading");

        // Callback on response.
        xhr.onreadystatechange = function() {
            // readyState 4 means the request is done.
            var DONE = 4;

            // status 200 is a successful return.
            var OK = 200;

            // Check response.
            if (xhr.readyState === DONE) {
                if (xhr.status === OK) {
                    let json = JSON.parse(xhr.responseText);

                    // Remove loading class from form
                    el.classList.remove("loading");

                    if (!response) {
                        return;
                    }

                    // Exist if not success.
                    if (!json.success) {
                        response.classList.add("error");

                        if ("message" in json.data) {
                            response.innerHTML = json.data.message;
                        }

                        if ("question_id" in json.data) {
                            let question = el.querySelector(
                                ".js-survey-question[data-question-id='" +
                                    json.data.question_id +
                                    "']"
                            );

                            if (!question) {
                                return;
                            }

                            question.classList.add("error");
                        }
                    } else {
                        // Success, display results
                        if ("results" in json.data) {
                            el.innerHTML = json.data.results;

                            let loadingEl = el.querySelector(".loading");

                            if (loadingEl) {
                                loadingEl.classList.remove("loading");
                            }
                        }
                    }
                } else {
                    // Error.
                }
            }
        };

        // Finally do the call with the right parametetrs.
        xhr.send(
            "action=submit-survey&id=" +
                id +
                "&wp_nonce=" +
                el.getAttribute("data-nonce") +
                "&answers=" +
                JSON.stringify(answers)
        );
    }
}

/**
 * Call ajax submit action on survey form submit.
 *
 * @param content
 */
document.addEventListener(
    "submit",
    function(event) {
        // If the form element doesn't have the right selector, bail
        if (!event.target.matches(".js-survey-form")) {
            return;
        }

        event.preventDefault();

        // Submit survey
        doSurveySubmit(event.target);
    },
    false
);
