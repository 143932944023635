// Toggle favorite.

document.addEventListener('click', function (event) {
    // Open favorite.
    if (event.target.matches('.js-open-favorite')) {
        event.target.parentElement.classList.add("is-opened");
        event.preventDefault();
    }

    // Close favorite.
    if (event.target.matches('.js-close-favorite')) {
        event.target.parentElement.classList.remove("is-opened");
        event.preventDefault();
    }

    // Click edit button.
    if (event.target.matches('.js-nav-fav-edit')) {
        event.target.classList.add("d-none");
        document.querySelector(".js-nav-fav-save").classList.remove("d-none");
        document.querySelector(".my-favorites--user").classList.add("is-editing");
        event.preventDefault();
    }

    // Click save button.
    if (event.target.matches('.js-nav-fav-save')) {
        event.target.classList.add("d-none");
        document.querySelector(".js-nav-fav-edit").classList.remove("d-none");
        document.querySelector(".my-favorites--user").classList.remove("is-editing");
        event.preventDefault();
    }

}, false);
